import React from "react"
import Title from "../Global/Title"
import ToolboxItem from "./ToolboxItem"

export default function Toolbox({ data, onViewTool }) {
  return (
    <section className="white-container">
      <div className="container-fluid">
        <div className="row">
          <div className="mx-auto">
            <div className="row-container">
              <Title title="Toolbox" />
            </div>
            <div className="separator" />

            <div className="row service-tool-grid">
              {data.map((tool, index) => (
                <ToolboxItem
                  key={tool.name}
                  name={tool.name}
                  description={tool.desc}
                  iconName={tool.icon}
                  onClick={() => (onViewTool ? onViewTool(tool) : null)}
                  index={index}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
