import React from "react"
import {
  FaWordpress,
  FaDrupal,
  FaShopify,
  FaMagento,
  FaQuestion,
  FaReact,
  FaEthereum,
  FaJira,
  FaNode,
  FaBitbucket,
  FaLaravel,
  FaSymfony,
} from "react-icons/fa"
import FlutterSvg from "../../icons/flutter_mono.svg"
import StrapiSvg from "../../icons/strapi_logo.svg"
import WoocommerceSvg from "../../icons/woocommerce_logo.svg"
import SyliusSvg from "../../icons/sylius_logo.svg"
import HyperledgerSvg from "../../icons/hyperledger_logo.svg"

const iconSize = "6rem"

export const getDynamicIcon = (name) => {
  switch (name) {
    case "laravel":
      return <FaLaravel size={iconSize} />

    case "symphony":
      return <FaSymfony size={iconSize} />

    case "node":
      return <FaNode size={iconSize} />

    case "bitbucket":
      return <FaBitbucket size={iconSize} />

    case "jira":
      return <FaJira size={iconSize} />

    case "ethereum":
      return <FaEthereum size={iconSize} />

    case "wordpress":
      return <FaWordpress size={iconSize} />

    case "drupal":
      return <FaDrupal size={iconSize} />

    case "shopify":
      return <FaShopify size={iconSize} />

    case "magento":
      return <FaMagento size={iconSize} />

    case "react":
    case "react-native":
      return <FaReact size={iconSize} />

    case "flutter":
      return <FlutterSvg className="custom-svg" style={{ width: iconSize }} />

    case "strapi":
      return <StrapiSvg className="custom-svg" style={{ width: iconSize }} />

    case "woocommerce":
      return <WoocommerceSvg style={{ width: iconSize }} />

    case "sylius":
      return <SyliusSvg className="custom-svg" style={{ width: iconSize }} />

    case "hyperledger":
      return (
        <HyperledgerSvg className="custom-svg" style={{ width: iconSize }} />
      )

    default:
      return <FaQuestion size={iconSize} />
  }
}

export default function ToolboxItem({
  name,
  description,
  iconName,
  onClick,
  index,
}) {
  return (
    <div
      className="col-lg-3 col-sm-6 col-xs-6 toolbox-item"
      role="button"
      focus
      onClick={onClick}
      onKeyDown={onClick}
      tabIndex={index}
    >
      {getDynamicIcon(iconName)}

      <div className="toolbox-overlay">
        <p>{description}</p>
        {getDynamicIcon(iconName)}
      </div>
    </div>
  )
}
