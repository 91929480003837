import React from "react"
import { Link } from "gatsby"
import { FaChevronLeft, FaChevronRight } from "react-icons/fa"
import { SERVICE } from "./data"

const PATHS = [
  "website-development",
  "ecommerce",
  "mobile-web-app",
  "blockchain",
]

const ECOM_PATHS = ["wordpress", "shopify"]

export default function NavRow({ pathname }) {
  const getRouteNames = () => {
    const pathArr = pathname.split("/")
    const path = pathArr[2]

    switch (path) {
      case "website-development":
        return { prev: PATHS[3], next: PATHS[1] }

      case "ecommerce":
        return { prev: PATHS[0], next: PATHS[2] }

      case "mobile-web-app":
        return { prev: PATHS[1], next: PATHS[3] }

      case "blockchain":
        return { prev: PATHS[2], next: PATHS[0] }

      case "wordpress":
        return { prev: PATHS[1], next: ECOM_PATHS[1] }

      case "shopify":
        return { prev: PATHS[1], next: ECOM_PATHS[0] }

      default:
        return { prev: PATHS[3], next: PATHS[1] }
    }
  }

  return (
    <div className="service-nav-row">
      <Link
        to={`/services/${getRouteNames().prev}`}
        state={{ serviceType: "test" }}
      >
        <div className="service-nav-item">
          <FaChevronLeft color="#2aa1c1" />
          <span>{SERVICE[getRouteNames().prev].title}</span>
        </div>
      </Link>

      <Link to={`/services/${getRouteNames().next}`}>
        <div className="service-nav-item">
          <span>{SERVICE[getRouteNames().next].title}</span>
          <FaChevronRight color="#2aa1c1" />
        </div>
      </Link>
    </div>
  )
}
