import React from "react"
import { getDynamicIcon } from "./ToolboxItem"

export default function ToolboxModal({ visible, content, onModalClose }) {
  const displayStyle = visible ? { display: "block" } : { display: "none" }

  return (
    <div
      className="toolbox-bg-modal"
      style={displayStyle}
      role="presentation"
      onClick={onModalClose}
      onKeyDown={onModalClose}
    >
      <div className="toolbox-modal">
        <p>{content.desc}</p>
        {getDynamicIcon(content.icon)}
      </div>
    </div>
  )
}
